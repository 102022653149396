import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { Analytics } from "@vercel/analytics/react"

import Footer from './components/Footer'
import Header from './components/Header'

const App = ({ className }) => {
  return (
    <>
      <div className={className}>
        <Header />
        <main>
          <Outlet />
        </main>
        <Footer />
      </div>
      <Analytics />
    </>
  )
}

export default styled(App)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  main {
    flex-grow: 1;
  }
`
