import styled from "styled-components";
import Kontakt from "./Kontakt";

import { Link } from "react-router-dom";

const Header = ({ className }) => {
  return (
    <header className = {className}>
      <nav>
        <a href="/#root">X-5001</a>
        <a href="/#leistungen">Leistungen</a>
        <a href="/#kontakt">Kontakt</a>
      </nav>
    </header>
  )
}

export default styled(Header)`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  a {
    color: hsl(0,0%,10%);
    text-decoration: none;
  }

  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 3.5rem;
    width: 100%;
    background: hsla(206,25%,85%,.5);
    backdrop-filter: blur(5rem);
    border-radius: 0;
    box-shadow: 0 .2rem .1rem hsla(206,25%,25%,.1), 0 .1rem .75rem hsla(206,25%,50%,.05);

    @media screen and (min-width: 400px) {
      max-width: 90%;
      border-radius: 0 0 .5rem .5rem;
    }

    @media screen and (min-width: 700px) {
      max-width: 500px;
    }

    a {
      padding: 1rem 1rem;
      font-size: 1.2rem;
      transition: color .2s ease-out;

      &:hover {
        margin-top: 2px;
        color: hsl(206,60%,35%);
        border-bottom: 2px solid hsl(206,50%,50%);
      }
    }
  }
`
