import styled from "styled-components"

const Imprint = ({ className }) => {
  return (
    <div className={className}>
      <div className="content">
        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>H&ouml;henarbeit X-5001 UG (haftungsbeschr&auml;nkt)<br />
        Friedenbergstra&szlig;e 41<br />
        66625 Nohfelden</p>

        <p>Handelsregister: HRB 107593<br />
        Registergericht: Amtsgericht Saarbr&uuml;cken</p>

        <p><strong>Vertreten durch:</strong><br />
        Roger Heidrich</p>

        <h2>Kontakt</h2>
        <p>Telefon: +49 (0) 157 59 32 06 28<br />
        E-Mail: info@x-5001.de</p>

        <h2>EU-Streitschlichtung</h2>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

        <h2>Verbraucher&shy;streit&shy;beilegung / Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

        <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
      </div>
    </div>
  )
}

export default styled(Imprint)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.5rem;
  padding: 1rem;
  width: 100%;

  & .content {
    width: 100%;
    background: hsl(206,33%,95%);
    padding: 2rem;
    border-radius: .5rem;

    @media screen and (min-width: 400px) {
      max-width: 90%;
    }

    @media screen and (min-width: 900px) {
      max-width: 700px;
    }

    & * {
      word-wrap: break-word;
    }
  }

  & h2 {
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
  }

  & p {
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
  }
`
