import styled from "styled-components"
import { useState } from "react"

const Kontakt = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleIsOpen = () => {
    setIsOpen(value => !value)
  }
  return (
    <div className={className} onClick={toggleIsOpen}>
      <span className="text">Kontakt</span>
      {isOpen && (
        <div className="PopUp">
          <span className="Name">Roger Heidrich</span>
          <span>E-Mail: <a href="mailto:der.seilzugangstechniker@gmail.com">der.seilzugangstechniker@gmail.com</a></span>
          <span>Telefon: <a href="tel:+4915759320628">015759320628</a></span>
        </div>
      )}
    </div>
  )
}


export default styled(Kontakt)`
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 1.25rem;
  transition: 0.4s;
  height: 100%;

  .Name{
    font-size: 1.25rem;
  }

  & span.text {
    padding: 0 0 0 1rem;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    &:hover {
      color: hsl(0, 0%, 30%);
    }
  }

  & a {
    text-decoration: none;
  }

  & .PopUp {
    position: absolute;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    min-width: calc(min(100vw, 350px));
    width: 100%;
    max-width: 400px;
    word-wrap: break-word;
    top: 4rem;
    right: 0;
    gap: .25rem;
    padding: 1rem;
    background-color: hsla(0, 0%, 100%, .9);
    backdrop-filter: blur(.5rem);
    border-radius: 0 .5rem .25rem .25rem;
    box-shadow: 0 .25rem .25rem hsla(0,0%,0%,.2), 0 .5rem 1rem hsla(0,0%,0%,.1);
    overflow: hidden;
    z-index: 1;

    @media screen and (min-width: 700px) {
      top: 5rem;
    }
  }
`
