import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Footer = ({ className }) => {
  return (
    <div className={className}>
      <Link to="impressum">Impressum</Link>
    </div>
  )
}

export default styled(Footer)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid hsl(0,0%,90%);
  margin-top: var(--gap);

  & a {
    color: hsl(0,0%,25%);
    text-decoration: none;
  }
`
