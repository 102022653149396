import styled from "styled-components"

const Anchor = ({ className, id }) => {
  return (
    <div className={className} id={id}>
    </div>
  )
}

export default styled(Anchor)`
  position: absolute;
  width: 0;
  height: 0;
  top: -5rem;
`
