import styled from "styled-components"

const SeoText = ({ className }) => {
  return (
    <div className={className}>
      <h2>Professionelle Höhenarbeiter für vielfältige Einsatzgebiete</h2>
      <p>In der heutigen Zeit sind Industriekletterer, auch als Höhenarbeiter oder Seilzugangstechniker bekannt, unverzichtbar, wenn es darum geht, Arbeiten in luftiger Höhe oder an schwer zugänglichen Stellen durchzuführen. Diese Spezialisten verfügen über umfassendes Fachwissen und beherrschen seilunterstützte Arbeitstechniken, die eine sichere und effiziente Ausführung von Aufgaben in anspruchsvollen Umgebungen ermöglichen.</p>
      <p>Industriekletterer kommen in einer Vielzahl von Branchen und Anwendungsbereichen zum Einsatz, darunter Bauwesen, Energie, Telekommunikation, Infrastruktur. Durch den Einsatz modernster Ausrüstung und innovativer Seilzugangstechniken sind unsere Höhenarbeiter in der Lage, Montage-, Wartungs-, Instandhaltungs-, Baumfäll- und Reinigungsarbeiten schnell und präzise durchzuführen, ohne dabei die Sicherheit zu vernachlässigen.</p>
      <p>Die Montage von Fassadenelementen, Beleuchtungsanlagen, Werbeschildern und Antennenanlagen gehört ebenso zu den Kompetenzen unserer Industriekletterer wie die Wartung und Instandhaltung von Gebäuden, Brücken und Denkmälern. Durch regelmäßige Inspektionen und Reparaturen stellen wir sicher, dass Ihre Anlagen stets in einwandfreiem Zustand sind und maximale Leistung erbringen.</p>
      <p>Auch im Bereich der Baumfällung sind unsere Seilzugangstechniker gefragt, wenn es darum geht, Bäume sicher und effizient zu fällen, ohne Schäden an umliegenden Gebäuden oder Infrastrukturen zu verursachen. Unsere Experten kümmern sich um alle Aspekte der Baumfällung, von der Planung bis zur Entsorgung.</p>
      <p>Schließlich bieten unsere Industriekletterer auch spezialisierte Reinigungsdienstleistungen für schwer zugängliche Flächen wie Glasfassaden, Dächer, Denkmäler und Brücken. Durch den Einsatz umweltfreundlicher Reinigungsmethoden und modernster Ausrüstung sorgen wir dafür, dass Ihre Objekte in neuem Glanz erstrahlen und ihren Wert erhalten.</p>
      <p>Bei der Suche nach einem zuverlässigen Partner für Industriekletterer, Höhenarbeiter und Seilzugangstechniker ist es wichtig, auf erfahrene und gut ausgebildete Fachkräfte zu setzen. Unsere Experten verfügen über die notwendige Erfahrung, um Ihre Projekte sicher und effizient umzusetzen, und bieten maßgeschneiderte Lösungen, die Ihren individuellen Anforderungen entsprechen. Vertrauen Sie auf unser Know-how und unsere Erfahrung &ndash; wir bringen Sie sicher und zuverlässig nach oben!</p>
      <p>In unserer schnelllebigen und technologiegetriebenen Welt ist es wichtiger denn je, auf die Expertise und Erfahrung von Industriekletterern, Höhenarbeitern und Seilzugangstechnikern zu vertrauen, um Projekte in anspruchsvollen Umgebungen erfolgreich umzusetzen. Unsere Fachkräfte sind bestens geschult und verfügen über umfassende Kenntnisse, die es Ihnen ermöglichen, eine Vielzahl von Aufgaben in unterschiedlichen Branchen und Einsatzbereichen zu meistern.</p>
      <p>Egal, ob es sich um den Neubau von Hochhäusern oder die Instandsetzung von Brücken handelt, wir sind Ihr zuverlässiger Partner für alle Arbeiten in luftiger Höhe. Wir setzen uns dafür ein, Ihnen effiziente Lösungen und hervorragenden Service zu bieten, der Ihren individuellen Anforderungen gerecht wird und die Sicherheit unserer Mitarbeiter und Kunden gewährleistet.</p>
      <p>Wenn Sie auf der Suche nach einem erfahrenen Industriekletterer, Höhenarbeiter oder Seilzugangstechniker sind, zögern Sie nicht, uns zu kontaktieren. Wir beraten Sie gerne und erstellen Ihnen ein individuelles Angebot, das genau auf Ihre Bedürfnisse zugeschnitten ist. Vertrauen Sie auf unsere Expertise und unser Engagement für Qualität, Sicherheit und Kundenzufriedenheit &ndash; wir sind bereit, Ihre Projekte in die Höhe zu bringen und Ihre Erwartungen zu übertreffen!</p>
    </div>
  )
}

export default styled(SeoText)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0 2rem;

  & p {
    margin: 0;
    max-width: calc(100% - 2rem);
    text-align: justify;

    @media screen and (min-width: 900px) {
      max-width: calc(900px - 2rem);
    }
  }
`
