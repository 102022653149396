import styled from "styled-components"
import { faWrench, faGear, faTree, faTreeCity, faBroom, faPhone } from "@fortawesome/free-solid-svg-icons"

import Showcase from "./Showcase"
import Anchor from "./Anchor"

const Showcases = [
  {
    icon: faWrench,
    label: "Montage",
    animation: 'wack',
    description: "Unsere Industriekletterer bieten professionelle Montagearbeiten in luftigen Höhen und an schwer zugänglichen Stellen. Wir kümmern uns um die Installation von Fassadenelementen, Beleuchtungsanlagen, Werbeschildern, Antennenanlagen und vielem mehr. Dank unserer Expertise und hochwertigen Ausrüstung garantieren wir eine präzise und effiziente Montage, die den höchsten Sicherheitsstandards entspricht.",
  },
  {
    icon: faGear,
    label: "Wartung",
    animation: 'rotate',
    description: "Regelmäßige Wartung und Instandhaltung sind entscheidend für die Langlebigkeit und Sicherheit von Gebäuden, Brücken und anderen Strukturen. Unsere erfahrenen Industriekletterer führen Inspektionen, Reparaturen und Wartungsarbeiten an schwer erreichbaren Orten durch, um potenzielle Probleme frühzeitig zu erkennen und zu beheben. Wir sorgen dafür, dass Ihre Anlagen stets in einwandfreiem Zustand sind und maximale Leistung erbringen."
  },
  {
    icon: faTree,
    label: "Baumfällung",
    animation: 'shake',
    description: "Wir bieten professionelle Baumfällung in schwierigen und begrenzten Raumverhältnissen. Unsere Baumkletterer setzen moderne Seilzugangstechniken ein, um Bäume effizient und sicher zu fällen, ohne Schäden an umliegenden Gebäuden oder Infrastrukturen zu verursachen. Ob es sich um städtische oder ländliche Umgebungen handelt, wir kümmern uns um alle Aspekte der Baumfällung, einschließlich Entsorgung."
  },
  {
    icon: faBroom,
    label: "Reinigung",
    animation: 'sweep',
    description: "Eine saubere Fassade und gepflegte Außenbereiche hinterlassen einen guten Eindruck und tragen zur Werterhaltung Ihrer Immobilie bei. Unsere Industriekletterer bieten spezialisierte Reinigungsdienstleistungen für schwer zugängliche Flächen wie Glasfassaden, Dächer, Denkmäler und Brücken. Wir nutzen umweltfreundliche Reinigungsmethoden und modernste Ausrüstung, um Schmutz, Algen, Moos und andere Verschmutzungen effektiv und materialschonend zu entfernen. Vertrauen Sie auf unsere Expertise, um Ihre Objekte in neuem Glanz erstrahlen zu lassen."
  }
]

const ShowcaseGrid = ({ className }) => {
  return (
    <div className={className}>
      <Anchor id="leistungen" />
      <h2>Leistungen</h2>
      <div className="grid">
        {Showcases.map(showcase => {
          return <Showcase {...showcase} key={showcase.label} />
        })}
      </div>
    </div>
  )
}

export default styled(ShowcaseGrid)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  padding: 0 1rem;

  @media screen and (min-width: 900px) {
    padding: 0;
  }

  & .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-gap: 1rem;

    @media screen and (min-width: 700px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 900px) {
      max-width: 900px;
    }
  }

  @keyframes wack {
    0% {
      transform: translateX(0) rotate(0deg);
    }
    50% {
      transform: translateX(0.4rem) translateY(0.2rem) rotate(15deg);
    }
    100% {
      transform: translateX(0) rotate(0deg);
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(60deg);
    }
  }

  @keyframes shake {
    0% {
      transform: translateX(0) rotate(0deg);
    }
    25% {
      transform: translateX(-0.2rem) rotate(-6deg);
    }
    50% {
      transform: translateX(0rem) rotate(0deg);
    }
    75% {
      transform: translateX(0.2rem) rotate(6deg);
    }
    100% {
      transform: translateX(0) rotate(0deg);
    }
  }

  @keyframes sweep {
    0% {
      transform: translateX(0) rotate(0deg);
    }
    50% {
      transform: translateX(0.5rem) rotate(-10deg);
    }
    100% {
      transform: translateX(0) rotate(0deg);
    }
  }
`
