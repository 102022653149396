import styled from "styled-components"

import Logo from "../assets/LogoCropped.png"
import ShowcaseGrid from "../components/ShowcaseGrid"
import ContactBlock from "../components/ContactBlock"
import SeoText from "../components/SeoText"

const Home = ({ className }) => {
  return (
    <div className={className}>
      <div className="jumbo">
        <img decoding="async" src={Logo} alt="X-5001 Logo"></img>
      </div>
      <ShowcaseGrid />
      <ContactBlock />
      <div className="paragraph">
        <h2>Industriekletterer - Höhenarbeiter - Seilzugangstechniker</h2>
        <p>
          Industriekletterer arbeiten mit seilunterstützten Arbeitstechniken, um auch an schwer zugänglichen
          Orten effizient und sicher zu arbeiten. Unsere qualifizierten Fachkräfte sind auf die
          verschiedensten Aufgabenbereiche spezialisiert und bieten Lösungen für Wartung, Montage,
          Inspektion und Reinigung in luftigen Höhen.
          <br /><br />
          Unsere Industriekletterer verfügen über umfangreiche Erfahrung und sind bestens geschult,
          um den höchsten Qualitäts- und Sicherheitsstandards gerecht zu werden. Wir nutzen modernste
          Ausrüstung und innovative Seilzugangstechniken, um den bestmöglichen Service für unsere Kunden
          sicherzustellen.
          <br /><br />
          Ob es sich um Bauwerke, Antennen oder
          Industrieanlagen handelt - wir sind Ihr zuverlässiger Partner für alle Arbeiten in der Höhe.
          Wir garantieren schnelle Reaktionszeiten und effiziente Lösungen, die Ihren Projekten und Budgets
          entsprechen.
          <br /><br />
          Sie haben Fragen zu diesem Thema oder möchten mit uns zusammenarbeiten? Sprechen Sie uns an.
          Wir beraten Sie gerne und erstellen Ihnen ein individuelles Angebot, das genau auf Ihre Anforderungen
          zugeschnitten ist. Vertrauen Sie auf unser Know-how und unsere Erfahrung - wir bringen Sie sicher
          und zuverlässig nach oben!
        </p>
      </div>

      <SeoText />
    </div>
  )
}

export default styled(Home)`
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  align-items: center;
  margin-top: calc(3.5rem + var(--gap));

  & h1 {
    font-size: 1.5rem;
    text-decoration: underline;
    justify-content: center;
    text-align: center;
    width: 100%;

    @media screen and (min-width: 700px) {
        font-size: 2rem;
    }
  }

  & h2 {
    text-align: center;
    font-size: 1.5rem;
    color: hsl(202,100%,25%);
    margin: 0;
  }

  & .paragraph {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap);
    padding: 0 2rem;

    & p {
      margin: 0;
      max-width: calc(100% - 2rem);
      text-align: justify;

      @media screen and (min-width: 900px) {
        max-width: calc(900px - 2rem);
      }
    }
  }

  & .jumbo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      width: 100%;
      max-width: 200px;
      object-fit: contain;
      transition: all .5s ease;
      animation: float 3s ease-in-out infinite;

      @media screen and (min-width: 400px) {
        max-width: 250px;
      }

      @media screen and (min-width: 700px) {
        max-width: 300px;
      }

      @keyframes float {
        0% {
          transform: translateY(calc(var(--gap) * 0.15));
        }
        50% {
          transform: translateY(-calc(var(--gap) * 0.15));
        }
        100% {
          transform: translateY(calc(var(--gap) * 0.15));
        }
      }
    }
  }
`
