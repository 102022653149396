import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Showcase = ({ className, icon, label, description }) => {
  return (
    <div className={className}>
      <div className="headline">
        <FontAwesomeIcon className="icon" icon={icon} size="4x" color="hsl(202,75%,45%)" />
        <div className="label">{label}</div>
      </div>
      <div className="description">
        {description}
      </div>
    </div>
  )
}

export default styled(Showcase)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  gap: 1rem;
  background: hsla(206,33%,90%,.75);
  border-radius: .5rem;

  & .headline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    & .label {
      font-size: 1.5rem;
    }
  }

  & .description {
    font-size: 0.8rem;
    text-align: left;
    text-align-last: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  & .icon {
    animation: ${props => props.animation} 5s ease-in-out infinite;
  }
`
