import styled from "styled-components"
import Anchor from "./Anchor"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"

const ContactBlock = ({ className }) => {
  return (
    <div className={className}>
      <Anchor id="kontakt" />
      <h2>Kontakt</h2>
      <div className="container">
        <div className="headline">
          <div>Sie haben Fragen, oder möchten mit uns zusammenarbeiten?</div>
          <div className="headline--prominent">Sprechen Sie uns an!</div>
        </div>
        <div className="info-container">
          <table>
            <tbody>
              <tr>
                <td><FontAwesomeIcon icon={faEnvelope} size="md" color="hsl(202,75%,20%)" /> E-Mail</td>
                <td><a href="mailto:info@x-5001.de">info@x-5001.de</a></td>
              </tr>
              <tr>
                <td><FontAwesomeIcon icon={faPhone} size="md" color="hsl(202,75%,20%)" /> Telefon</td>
                <td><a href="tel:+4915759320628">015759320628</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default styled(ContactBlock)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  position: relative;

  & .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 2rem 2rem;
    background: hsla(206,33%,90%,.75);

    @media screen and (min-width: 700px) {
      max-width: 90%;
      border-radius: .5rem;
    }

    @media screen and (min-width: 900px) {
      max-width: 900px;
    }
  }

  & .headline {
    display: flex;
    flex-direction: column;
    text-align: center;

    &--prominent {
      font-size: 1.25rem;
      color: hsl(206,75%,25%);
    }
  }

  & .info-container {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    background: hsla(206,33%,50%,.2);
    border-radius: .5rem;

    table {
      border-collapse: separate;
      border-spacing: 1rem .5rem;
      font-size: 1.2rem;

      td:first-child {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }

    a {
      color: hsl(206,75%,25%);
      text-decoration: none;
      border-bottom: 1px solid hsla(206,75%,25%,.33);
    }
  }
`
